<template>
  <!--Carousel Image-->
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
    >
      <b-carousel-slide
        v-for="(slider, index) in sliders"
        :key="`slider-${index}`"
        :img-src="imagePath(slider.image)"
      >
      </b-carousel-slide>
    </b-carousel>

    <!--kalkulator cerdas-->
    <b-container fluid class="pt-4 px-5">
      <b-row class="p-4">
        <h3 class="m-auto">Kalkulator Cerdas</h3>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-col
          sm="12"
          md="3"
          lg="2"
          class="mb-2"
          v-for="calculator in calculators"
          :key="`calculator-${calculator.id}`"
        >
          <calculator-card
            :id="calculator.id"
            :label="calculator.label"
            :logo="calculator.logo"
            :path="calculator.path"
          ></calculator-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row class="p-4 mt-5">
        <h3 class="m-auto">Kegiatan</h3>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="12">
          <carousel
            :autoplay="true"
            :speed="100"
            :scrollPerPage="false"
            :loop="true"
            :perPageCustom="[
              [300, 1],
              [768, 2],
              [1024, 3],
            ]"
          >
            <slide
              v-for="activity in activities"
              :key="`activity-${activity.id}`"
              class="px-2"
            >
              <activity-card :image="activity.image" />
            </slide>
          </carousel>
        </b-col>
      </b-row>
    </b-container>

    <!--artikel-->
    <b-container class="pt-4 pb-4">
      <b-row class="p-4">
        <h3 class="m-auto">Artikel</h3>
      </b-row>
      <b-row class="mb-4">
        <b-col
          sm="4"
          v-for="(posts, index) in recentPosts"
          :key="`col-article-${index}`"
        >
          <article-card-compact
            v-for="(post, index) in posts"
            :key="`article-${index}`"
            :id="post.ID"
            :title="post.title"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-button to="/articles" variant="outline-danger" class="m-auto"
          >Artikel Lainnya</b-button
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import { Carousel, Slide } from 'vue-carousel'
import ArticleCardCompact from '../components/ArticleCardCompact.vue'
import ActivityCard from '../components/ActivityCard.vue'
import CalculatorCard from '../components/CalculatorCard.vue'
import calculators from '../data/calculators.json'
import activities from '../data/activities.json'
import sliders from '../data/sliders.json'

export default {
  name: 'Home',
  components: {
    ArticleCardCompact,
    ActivityCard,
    CalculatorCard,
    Carousel,
    Slide,
  },

  data() {
    return {
      cerdasKeuanganUrl: this.getApiURL('cerdaskeuangan'),
      bundaCerdasKeuanganUrl: this.getApiURL('bundacerdaskeuangan'),
      queryOptions: {
        number: 3,
        page: 1,
        offset: 0,
        order_by: 'date',
        order: 'DESC',
      },
      recentPosts: [],
      activities: activities,
      sliders: sliders,
      calculators: calculators,
    }
  },
  methods: {
    imagePath(image) {
      return require(`@/assets/images/sliders/${image}`)
    },
    getApiURL(subdomain) {
      return `https://public-api.wordpress.com/rest/v1.1/sites/${subdomain}.wordpress.com/posts/`
    },
    getRecentPosts() {
      axios
        .all([
          axios.get(this.bundaCerdasKeuanganUrl, { params: this.queryOptions }),
          axios.get(this.cerdasKeuanganUrl, { params: this.queryOptions }),
        ])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            const posts = [
              ...firstResponse.data.posts,
              ...secondResponse.data.posts,
            ]

            const len = posts.length / 3

            const slicedPosts = [
              posts.slice(0, len),
              posts.slice(len, len * 2),
              posts.slice(len * 2),
            ]

            this.recentPosts = slicedPosts
          })
        )
        .catch((error) => {
          this.error = error
        })
    },
  },
  mounted() {
    this.getRecentPosts()
  },
}
</script>
