import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () =>
      import(/* webpackChunkName: "articles" */ '../views/Articles'),
  },
  {
    path: '/article/:id',
    name: 'ArticleDetail',
    component: () =>
      import(/* webpackChunkName: "article-detail" */ '../views/ArticleDetail'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () =>
      import(/* webpackChunkName: "testimonials" */ '../views/Testimonials'),
  },
  {
    path: '/partnership/:slug',
    name: 'PartnershipDetail',
    component: () =>
      import(
        /* webpackChunkName: "partnership" */ '../views/PartnershipDetail'
      ),
  },
  {
    path: '/calculators',
    name: 'Calculator',
    component: () =>
      import(/* webpackChunkName: "calculators" */ '../views/Calculator'),
  },
  {
    path: '/calculators/time-value-of-money',
    name: 'CalculatorTimeValueOfMoney',
    component: () =>
      import(
        /* webpackChunkName: "calculator-time-value-of-money" */ '../views/calculators/TimeValueOfMoney.vue'
      ),
  },
  {
    path: '/calculators/financial-checkup',
    name: 'CalculatorFinancialCheckup',
    component: () =>
      import(
        /* webpackChunkName: "calculator-financial-checkup" */ '../views/calculators/FinancialCheckup.vue'
      ),
  },
  {
    path: '/calculators/dana-investasi',
    name: 'CalculatorDanaInvestasi',
    component: () =>
      import(
        /* webpackChunkName: "calculator-dana-investasi" */ '../views/calculators/DanaInvestasi.vue'
      ),
  },
  {
    path: '/calculators/dana-pendidikan',
    name: 'CalculatorDanaPendidikan',
    component: () =>
      import(
        /* webpackChunkName: "calculator-dana-pendidikan" */ '../views/calculators/DanaPendidikan.vue'
      ),
  },
  {
    path: '/calculators/dana-pensiun',
    name: 'Calculator',
    component: () =>
      import(
        /* webpackChunkName: "calculator-dana-pensiun" */ '../views/calculators/DanaPensiun.vue'
      ),
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
})

export default router
