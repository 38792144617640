<template>
  <b-list-group-item :to="`/article/${id}`">
    <span v-if="title.length > 30">
      {{ title.slice(0, 30) + '..' }}
    </span>

    <span v-else>{{ title }}</span>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ArticleCardCompact',
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
