<template>
  <b-link :to="`/calculators${path}`" class="link-calculator">
    <b-card no-body class="shadow-sm border-light">
      <b-card-body
        class="d-flex flex-column justify-content-center align-items-center p-3"
      >
        <h5 class="mb-4 text-center mt-2">{{ label }}</h5>
        <b-img
          :src="logoPath"
          class="mb-2"
          alt="calculator icon"
          width="100px"
          fluid
        />
      </b-card-body>
    </b-card>
  </b-link>
</template>

<script>
export default {
  name: 'CalculatorCard',
  props: {
    id: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
  computed: {
    logoPath() {
      return require(`@/assets/images/calculators/${this.logo}`)
    },
  },
}
</script>
