<template>
  <b-navbar toggleable="lg" class="shadow-sm">
    <b-navbar-brand to="/">
      <b-img
        :src="require('../assets/logo.jpg')"
        class="mx-1"
        alt="logo"
        width="160px"
        fluid
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/" class="p-3">Home</b-nav-item>
        <b-nav-item to="/about" class="p-3">About Us</b-nav-item>
        <b-nav-item to="/articles" class="p-3">Learn</b-nav-item>
        <b-nav-item-dropdown text="Practice" class="p-3">
          <b-dropdown-item to="/calculators">Kalkulator Cerdas</b-dropdown-item>
          <b-dropdown-item to="/testimonials">Testimoni</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Partnership" class="p-3">
          <b-dropdown-item
            v-for="partner in partnership"
            :key="`partner-${partner.id}`"
            :to="`/partnership/${partner.slug}`"
            >{{ partner.name }}</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import partnership from '../data/partnership.json'

export default {
  name: 'Header',
  data() {
    return {
      partnership: partnership,
    }
  },
}
</script>
