<template>
  <footer class="text-muted mt-5">
    <b-container>
      <b-row class="mt-3">
        <b-col md class="my-4">
          <b-img
            :src="require('../assets/logo.jpg')"
            class="mb-2"
            alt="logo"
            width="250px"
            fluid
          />
        </b-col>
        <b-col md class="my-4">
          <p class="small">
            PT Cerdas Keuangan Indonesia
            <br />
            <br />
            TALAVERA OFFICE PARK
            <br />
            Lantai 28, JL. TB. Simatupang Kav. 22-26, Cilandak Jakarta Selatan
            12430
          </p>
          <b-link
            href="https://www.instagram.com/cerdaskeuangan/"
            target="_blank"
            class="mr-2"
          >
            <b-img
              :src="require('../assets/images/instagram.png')"
              class="mb-2"
              alt="instagram logo"
              width="30px"
              fluid
            />
          </b-link>
          <b-link
            href="https://www.youtube.com/@cerdaskeuangan9722"
            target="_blank"
            class="mr-2"
          >
            <b-img
              :src="require('../assets/images/youtube.png')"
              class="mb-2"
              alt="youtube logo"
              width="30px"
              fluid
            />
          </b-link>
          <b-link
            href="https://open.spotify.com/show/3UrIYbnp8ndHVatG5gizVJ?si=GyN-5Tg5TvKLjfdEjlxURQ&utm_source=copy-link&nd=1"
            target="_blank"
            class="mr-2"
          >
            <b-img
              :src="require('../assets/images/spotify.png')"
              class="mb-2"
              alt="spotify logo"
              width="30px"
              fluid
            />
          </b-link>
          <b-link
            href="https://www.facebook.com/CerdasKeuangan/"
            target="_blank"
            class="mr-2"
          >
            <b-img
              :src="require('../assets/images/facebook.png')"
              class="mb-2"
              alt="facebook logo"
              width="30px"
              fluid
            />
          </b-link>
          <b-link
            href="https://twitter.com/CerdasKeuangan"
            target="_blank"
            class="mr-2"
          >
            <b-img
              :src="require('../assets/images/twitter.png')"
              class="mb-2"
              alt="twitter logo"
              width="30px"
              fluid
            />
          </b-link>
        </b-col>
        <b-col md class="my-4">
          <b-link to="/about" class="text-reset small font-weight-bolder">
            About Us
          </b-link>
          <br />
          <b-link to="/articles" class="text-reset small font-weight-bolder">
            Learn
          </b-link>
          <br />
          <span>
            <b-link to="/" class="text-reset small font-weight-bolder">
              Practice
            </b-link>
            -
            <b-link to="/calculators" class="text-reset small">
              Kalkulator Cerdas
            </b-link>
            -
            <b-link to="/testimonials" class="text-reset small">
              Testimoni
            </b-link>
          </span>
        </b-col>
      </b-row>
    </b-container>
    <div
      class="text-center p-2"
      style="background-color: rgba(0, 0, 0, 0.05); font-size: 12px"
    >
      Copyright © Cerdas Keuangan {{ currentYear }}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    },
  },
}
</script>
