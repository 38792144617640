import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.prototype.emptyToZero = function (value) {
  if (this.isEmpty(value)) {
    return 0
  }

  return value
}

Vue.prototype.unformatCurrency = function (value, parseToFloat = true) {
  if (this.isEmpty(value)) {
    return '0'
  }

  // Remove existing commas from the value
  const inputValue = value.toString().replace(/,/g, '')

  // Remove all non-numeric characters except the dot
  const numericValue = inputValue.replace(/[^0-9.-]+/g, '')

  return parseToFloat ? parseFloat(numericValue) : numericValue
}

Vue.prototype.formatToCurrency = function (value) {
  if (this.isEmpty(value)) {
    return '0'
  }

  const formattedValue = new Intl.NumberFormat({
    currency: 'IDR',
  }).format(value)

  return formattedValue
}

Vue.prototype.toZeroMinus = function (value) {
  return value === '0' ? -0 : value
}

Vue.prototype.isEmpty = function (value) {
  return value === null || value === ''
}

Vue.directive('currency', {
  bind: function (el, binding, vnode) {
    el.addEventListener('keyup', function () {
      let value = vnode.context.unformatCurrency(el.value, false)

      if (value.split('.')[1] != '' && value.split('-')[1] != '') {
        let formattedValue = vnode.context.formatToCurrency(value)
        formattedValue = formattedValue === '0' ? '' : formattedValue
        el.value = formattedValue
      }

      vnode.context[binding.expression] = value
    })
  },
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
