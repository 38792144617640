<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer'
import Header from './components/Header'

export default {
  name: 'App',
  metaInfo: {
    title: 'Cerdas Keuangan',
  },
  components: {
    Header,
    Footer,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f4f8fb;
  font-size: 1.2rem;
}

#app h5 {
  font-size: 1.5rem;
}

#app h3 {
  font-size: 2rem;
}

.navbar,
footer {
  background-color: #fff;
}

.card {
  height: 100%;
}

.card-footer {
  background-color: #fff !important;
  border-top: none !important;
}

.article-img {
  width: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 491px;
}

.testimonial-summary,
.testimonial-occupation {
  white-space: pre-line;
}

.testimonial-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.link-calculator,
.link-calculator:hover {
  color: #2c3e50;
}

.link-calculator:hover {
  text-decoration: none;
}

.link-calculator > .card {
  border-width: 2px;
  border-color: #fff;
}

.link-calculator:hover > .card {
  border-color: #dc3545 !important;
  border-width: 2px;
  transition: border-color 0.5s linear;
}

.calc-button {
  width: 120px;
}

.partnership > .logo {
  float: left;
  margin-right: 1rem;
}

@media only screen and (min-width: 769px) {
  .footer {
    height: 250px;
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (max-width: 769px) {
  .partnership > .logo {
    float: none;
    margin-right: 0;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 1200px) {
  .footer {
    height: 230px;
    position: absolute;
    bottom: 0;
  }
}
</style>
